import Layout from '@components/layout/Main'
import Button from '@components/button/Button'
import { LOGIN_PAGE } from '@constants/routes'
import useT from '@hooks/useTranslation'

import styles from './404.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

export default function Page404 () {
    return (
        <Layout navdown>
            <Layout.NavSpace />
            <section className={`container min-height ${styles.section}`}>
                <div className={styles.wrap}>

                    <h1 className={styles.h}>{useT('Błąd')} 404</h1>
                    <p className={styles.teal}>{useT('Nie znaleziono strony')}.</p>
                    <img alt="404" className={styles.img} src={HOST + '/icons/404.jpeg'} />
                    <br/>
                    <h2>{useT('Dokąd chcesz przejść')}?</h2>
                    <div className={styles.btns}>
                        <Button href={HOST} teal>{useT('Strona główna')}</Button>
                        <Button href={HOST + '/samochody-osobowe'} teal>{useT('Samochody osobowe')}</Button>
                        <Button href={HOST + LOGIN_PAGE} teal>{useT('Logowanie')}</Button>
                    </div>
                </div>
            </section>
        </Layout>
    )
}